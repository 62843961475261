import { resolveObjectURL } from "buffer";
import { sl } from "date-fns/locale";
import { StringParam } from "firebase-functions/lib/params/types";
import { get } from "http";

export interface ISlide {
	id: string;
	title: string;
	index: number;
}

export interface ISlideRange {
	slides: ISlide[];
	shapes: IShape[];
}

interface IShape {
	id: string;
}

export interface IPowerPointSlide {
	id: string;
	title: string;
	index: number;
	slideTitle: string;
	slideIndex: number;
	shapeCount: number;
	shapeTypes: string[]; // Array of strings to hold shape types
}

interface IPowerPointSlideRange {
	slides: IPowerPointSlide[];
}
/* Office types have not been updated with isDarkTheme - Create change request for office.js team */
export interface OfficeThemeLatest extends Office.OfficeTheme {
	isDarkTheme: boolean;
}

/*
export function uint8ArrayToBase64(ui8array: Uint8Array) {
	let binaryString = "";
	ui8array.forEach((byte) => {
		binaryString += String.fromCharCode(byte);
	});
	return window.btoa(binaryString);
} */
export function uint8ArrayToBase64(ui8array: Uint8Array): string {
	const CHUNK_SIZE = 0x8000; // arbitrary number
	let index = 0;
	const length = ui8array.length;
	let result = "";
	let slice: Uint8Array;
	while (index < length) {
		slice = ui8array.subarray(index, Math.min(index + CHUNK_SIZE, length));
		result += String.fromCharCode.apply(null, Array.from(slice));
		index += CHUNK_SIZE;
	}
	return window.btoa(result);
}

export function addFileAttachmentFromBase64(
	fileContents: string,
	attachmentName: string
): boolean {
	// Get a handle on the current item (message or appointment).
	var item = Office.context.mailbox.item;

	// Call the addFileAttachmentFromBase64Async method to add the attachment.
	Office.context.mailbox.item.addFileAttachmentFromBase64Async(
		fileContents,
		attachmentName,
		function (result) {
			if (result.status === Office.AsyncResultStatus.Succeeded) {
				console.log("Attachment added successfully");
				return true;
			} else {
				console.log("Error adding attachment: " + result.error.message);
				return false;
			}
		}
	);
	return false;
}

export async function loadFileName(): Promise<string | null> {
	return new Promise((resolve) => {
		Office.context.document.getFilePropertiesAsync(null, (res) => {
			if (res && res.value && res.value.url) {
				let name = res.value.url.substr(
					res.value.url.lastIndexOf("\\") + 1
				);
				resolve(name);
			}
			resolve(null);
		});
	});
}

export const saveDocumentSettings = async (data: { [key: string]: any }) => {
	for (const key in data) {
		Office.context.document.settings.set(key, data[key]);
	}
	await Office.context.document.settings.saveAsync();
};

export const getDocumentSettings = async (data: Array<string>) => {
	const ret: { [key: string]: string } = {};

	data.forEach(async (key) => {
		console.log("key", key);
		const value = await Office.context.document.settings.get(key);
		ret[key] = value;
	});

	return ret;
};

export async function tagForAutoOpen(): Promise<void> {
	return new Promise(async (resolve) => {
		Office.context.document.settings.set(
			"Office.AutoShowTaskpaneWithDocument",
			true
		);
		await Office.context.document.settings.saveAsync();
		console.log("*************Document tagged for auto open*************");
		resolve();
	});
}

export async function insertSlideExternal(
	dataBase64: string,
	afterSelected?: boolean
): Promise<void> {
	await new Promise<void>((resolve, reject) => {
		Office.context.document.getSelectedDataAsync(
			Office.CoercionType.SlideRange,
			async (asyncResult: Office.AsyncResult<ISlideRange>) => {
				var targetId: undefined | string = undefined;
				var targetIndex: number = 0;
				var goToIndex: number = 0;

				if (asyncResult.status == Office.AsyncResultStatus.Failed) {
					console.error(
						"InsertSlideExternal: Unable to get selected data: " +
							asyncResult.error.message
					);
				} else {
					var slides = await getSlides();
					const index = asyncResult.value.slides[0].index - 1;
					targetIndex = index + (afterSelected ? 0 : -1);
					goToIndex = index + (afterSelected ? 1 : 0);

					console.log(
						"Target index, afterSelected",
						index,
						targetIndex,
						afterSelected
					);

					// Get slide ID of the target index
					targetId = "" + slides[Math.max(targetIndex, 0)].id;
				}
				try {
					await PowerPoint.run(async function (context) {
						// get currently selected slide
						const slideRange =
							await context.presentation.insertSlidesFromBase64(
								dataBase64,
								{ targetSlideId: targetId }
							);

						await context.sync();

						slides = await getSlides();

						const nextSlideId = slides[goToIndex].id;
						// Go to to the new slide
						try {
							await goToSlideById(nextSlideId);
						} catch (error) {
							// Not critical - can fail when no slides exist in the presentation yet
						}
					});

					resolve();
				} catch (error) {
					reject(error);
				}
			}
		);
	});
}

export async function insertSlideAtId(
	dataBase64: string,
	targetId: string
): Promise<void> {
	await PowerPoint.run(async function (context) {
		// get currently selected slide
		await context.presentation.insertSlidesFromBase64(dataBase64, {
			targetSlideId: targetId,
		});

		await context.sync();
	});
}

export async function getCurrentSlide(): Promise<ISlide> {
	return new Promise<ISlide>((resolve, reject) => {
		Office.context.document.getSelectedDataAsync(
			Office.CoercionType.SlideRange,
			async (asyncResult: Office.AsyncResult<ISlideRange>) => {
				var targetId: undefined | string = undefined;

				if (asyncResult.status == Office.AsyncResultStatus.Failed) {
					reject(
						"GetCurrentSlide: Unable to get selected data: " +
							asyncResult.error.message
					);
				} else {
					console.log("GOT CURRENT SLIDE: ", asyncResult.value);
					const allSlideIds = await getAllSlideIds();
					const fullSlideId = allSlideIds.find(
						(id) =>
							id.split("#")[0] == asyncResult.value.slides[0].id
					);
					resolve({
						id: fullSlideId,
						title: asyncResult.value.slides[0].title,
						index: asyncResult.value.slides[0].index - 1,
					});
				}
			}
		);
	});
}

export async function getAllShapes(): Promise<PowerPoint.Shape[]> {
	return new Promise<PowerPoint.Shape[]>(async (resolve, reject) => {
		await PowerPoint.run(async (context) => {
			try {
				const slide = context.presentation.slides.getItemAt(0);
				const shapes = slide.shapes;

				// Load all the shapes in the collection without loading their properties.
				shapes.load("items/Name");
				await context.sync();

				const s = shapes.items.map((shape) => shape);
				//shapes.items.forEach(function (shape) {
				//	console.log(shape);
				//});
				resolve(s);
				await context.sync();
			} catch (error) {
				reject(error);
			}
		});
	});
}

export async function getIdBootstrapToken(): Promise<string> {
	try {
		//console.log("Getting bootstrap token in function getIdBoostrapToken");
		const accessToken = await Office.auth.getAccessToken({
			allowSignInPrompt: true,
			allowConsentPrompt: true,
			//forMSGraphAccess: false, // TODO: This is a outlook only thing and should be enabled for PowerPoint
		});
		//console.log("Access token", accessToken);
		return accessToken;
	} catch (error) {
		console.log("Error obtaining token", error);
		return null;
	}
}

export async function getShapeById(shapeId: string): Promise<PowerPoint.Shape> {
	return new Promise(async (resolve, reject) => {
		await PowerPoint.run(async (context) => {
			// Attempt to get the shape by its ID
			context.presentation.load("slides");
			await context.sync();
			const slide = context.presentation.slides.getItemAt(0);
			const shape = slide.shapes.getItem(shapeId);
			shape.load("id"); // Load properties of the shape, in this case, just the ID

			// Synchronize and resolve the promise if successful
			slide.context
				.sync()
				.then(() => {
					resolve(shape);
				})
				.catch((error) => {
					reject(`Failed to get shape: ${error}`);
				});
		});
	});
}

export async function setSelectedShapes(shapesIds: string[]) {
	// Selects the first two shapes on slide 1.
	await PowerPoint.run(async (context) => {
		context.presentation.load("slides");
		await context.sync();
		const slide1 = context.presentation.slides.getItemAt(0);
		//slide1.load("shapes");
		await context.sync();
		//const shapes = slide1.shapes;
		//const shape1 = shapes.getItemAt(0);
		//const shape2 = shapes.getItemAt(1);
		//shape1.load("id");
		//shape2.load("id");
		//await context.sync();
		slide1.setSelectedShapes(shapesIds);
		await context.sync();
	});
}

// https://raw.githubusercontent.com/OfficeDev/office-js-snippets/prod/samples/powerpoint/shapes/get-set-shapes.yaml

export async function goToSlideById(id: string): Promise<void> {
	return new Promise((resolve, reject) => {
		Office.context.document.goToByIdAsync(
			id.split("#")[0],
			Office.GoToType.Slide,
			function (result: Office.AsyncResult<any>) {
				if (result.status === Office.AsyncResultStatus.Succeeded) {
					console.log("Navigated to slide with id ", id);
					resolve();
				} else {
					console.error(
						"Error navigating to slide: ",
						id,
						result.error.message
					);
					reject();
				}
			}
		);
	});
}

export async function deleteSlideAt(idx: number): Promise<void> {
	await PowerPoint.run(async function (context) {
		// The slide index is zero-based.
		const slide = context.presentation.slides.getItemAt(idx);
		slide.delete();

		await context.sync();
	});
}

export async function deleteSlideById(slideId: string): Promise<void> {
	await PowerPoint.run(async function (context) {
		console.log("Deleting slide with id", slideId);
		const slide = context.presentation.slides.getItem(slideId);
		await context.sync();
		slide.delete();
		await context.sync();
	});
}

export async function getSelectedShapes(): Promise<PowerPoint.Shape[]> {
	const shapeIds: PowerPoint.Shape[] = [];
	await PowerPoint.run(async (context) => {
		const shapes = context.presentation.getSelectedShapes();

		const shapeCount = shapes.getCount();
		await context.sync();
		shapes.load("items");
		await context.sync();
		shapes.items.map((shape, index) => {
			shapeIds.push(shape);
		});
	});
	return shapeIds;
}

// Get all of the content from a PowerPoint document in 100-KB chunks of text.
export const getFileAsBase64 = (
	fileType?: Office.FileType
): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		let aggregatedData: Array<number> = [];

		function sendFile() {
			Office.context.document.getFileAsync(
				fileType || Office.FileType.Compressed,
				{ sliceSize: 50000 },
				function (result) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						const myFile = result.value;
						const state = {
							file: myFile,
							counter: 0,
							sliceCount: myFile.sliceCount,
						};

						console.log(
							"Getting file of " + myFile.size + " bytes"
						);
						getSlice(state);
					} else {
						console.log(result.status);
						reject("Failed to get file");
					}
				}
			);
		}

		function getSlice(state: {
			file?: any;
			counter: any;
			sliceCount: any;
			data?: any;
		}) {
			state.file.getSliceAsync(
				state.counter,
				function (result: {
					status: Office.AsyncResultStatus;
					value: any;
				}) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						console.log(
							"Sending piece " +
								(state.counter + 1) +
								" of " +
								state.sliceCount
						);
						// Aggregate the data from each slice
						try {
							//console.log("RESULT DATA:", result.value.data);
							aggregatedData.push(...result.value.data);
							//aggregatedData.push(...[1,2,3]);
							//console.log("Aggregated", aggregatedData);
						} catch (error) {
							console.log("Error", error);
						}
						state.counter++;

						if (state.counter < state.sliceCount) {
							getSlice(state);
						} else {
							closeFile(state);
						}
					} else {
						console.log(result.status);
						reject("Failed to get slice");
					}
				}
			);
		}

		function closeFile(state: {
			counter: any;
			sliceCount: any;
			file?: any;
		}) {
			state.file.closeAsync(function (result: {
				status: Office.AsyncResultStatus;
			}) {
				if (result.status === Office.AsyncResultStatus.Succeeded) {
					console.log("File closed.");
					console.log("And we have some data", aggregatedData);

					//const uint8Array = new Uint8Array(aggregatedData);
					console.log(aggregatedData.length);

					const uint8Array = new Uint8Array(aggregatedData);

					console.log("uint array", uint8Array);

					// Convert Uint8Array to base64
					//const uint8Array = new Uint8Array([65, 66]); // Example data
					try {
						const base64String = uint8ArrayToBase64(uint8Array);
						console.log(
							"base64 conversion ok",
							base64String.length
						);
						resolve(base64String);
					} catch (error) {
						console.log("Error", error);
						reject("Failed to return base64 string");
					}

					resolve("hi");
				} else {
					console.log("File couldn't be closed.");
					reject("Failed to close file");
				}
			});
		}

		sendFile();
	});
}; // getFileAsBase64

export const getFileContents = (
	fileType?: Office.FileType
): Promise<Uint8Array> => {
	return new Promise<Uint8Array>((resolve, reject) => {
		let aggregatedData: Uint8Array;

		function sendFile() {
			Office.context.document.getFileAsync(
				fileType || Office.FileType.Compressed,
				{ sliceSize: 500000 }, // Increase slice size to 500,000 bytes
				function (result) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						const myFile = result.value;
						const state = {
							file: myFile,
							sliceCount: myFile.sliceCount,
							size: myFile.size,
						};

						console.log(
							"Getting file of " + myFile.size + " bytes"
						);
						aggregatedData = new Uint8Array(state.size);
						getSlicesInBatches(state, 10); // Retrieve slices in batches of 10
					} else {
						console.log(result.status);
						reject("Failed to get file");
					}
				}
			);
		}

		async function getSlicesInBatches(
			state: { file: any; sliceCount: any; size: number },
			batchSize: number
		) {
			for (let i = 0; i < state.sliceCount; i += batchSize) {
				const slicePromises = [];
				for (
					let j = 0;
					j < batchSize && i + j < state.sliceCount;
					j++
				) {
					slicePromises.push(getSlice(state.file, i + j));
				}

				try {
					const slices = await Promise.all(slicePromises);
					let offset = i * 500000; // sliceSize is 500,000 bytes
					for (const slice of slices) {
						aggregatedData.set(slice.data, offset);
						offset += slice.data.length;
					}
				} catch (error) {
					console.log(error);
					reject("Failed to get slices");
					return;
				}
			}
			closeFile(state);
		}

		function getSlice(file: any, index: number): Promise<any> {
			return new Promise((resolve, reject) => {
				file.getSliceAsync(
					index,
					function (result: {
						status: Office.AsyncResultStatus;
						value: any;
					}) {
						if (
							result.status === Office.AsyncResultStatus.Succeeded
						) {
							console.log(`Retrieved slice ${index + 1}`);
							resolve(result.value);
						} else {
							reject(`Failed to get slice ${index + 1}`);
						}
					}
				);
			});
		}

		function closeFile(state: { file: any }) {
			state.file.closeAsync(function (result: {
				status: Office.AsyncResultStatus;
			}) {
				if (result.status === Office.AsyncResultStatus.Succeeded) {
					console.log("File closed.");
					resolve(aggregatedData);
				} else {
					console.log("File couldn't be closed.");
					reject("Failed to close file");
				}
			});
		}

		sendFile();
	});
};
// Slides i presentation and titles
// getSlides.ts

export const getSlideImage = async (
	slideIndex: number
): Promise<[string, string]> => {
	return PowerPoint.run(async (context) => {
		console.log("Getting slide image and text");
		const slides = context.presentation.slides;
		slides.load("items/shapes");
		await context.sync();

		const slide = slides.getItemAt(slideIndex);
		slide.load("id");
		await context.sync();

		const result = slide.getImageAsBase64();
		//slide.toJSON();
		//const result = slide.exportAsBase64();
		await context.sync();

		const shapes = slide.shapes;
		shapes.load("items");

		await context.sync();

		let slideText = "";

		for (let shape of shapes.items) {
			console.log("Extracting text from", shape);
			try {
				if (
					shape.type != PowerPoint.ShapeType.group &&
					shape.textFrame
				) {
					shape.textFrame.textRange.load("text");
					await context.sync();
					slideText += shape.textFrame.textRange.text + "\n";
				} else {
					// The shape is a group, hack the text out of it
					const groupItems = shape.toJSON();
					console.log("######Group items#######", groupItems);
				}
			} catch (error) {
				console.log("Error", error);
			}
		}

		return [result.value, slideText];
	});
};

export async function setSlideTag(slideId: string, key: string, value: string) {
	await PowerPoint.run(async (context) => {
		const slides = context.presentation.slides;
		slides.load("items");
		await context.sync();
		// get slide index of the slide with the given id
		const slideIndex = context.presentation.slides.items.findIndex(
			(slide) => slide.id === slideId
		);
		if (slideIndex === -1) {
			throw new Error(`Slide not found: ${slideId}`);
		}
		const slide = context.presentation.slides.getItemAt(slideIndex);

		const tags = slide.tags.add(key, value);
		await context.sync();
		slide.tags.load("key, value");

		await context.sync();

		for (let i = 0; i < slide.tags.items.length; i++) {
			console.log(
				"Added key " +
					JSON.stringify(slide.tags.items[i].key) +
					" with value " +
					JSON.stringify(slide.tags.items[i].value)
			);
		}
	});
}

export function getAllSlideIds(): Promise<string[]> {
	return new Promise((resolve, reject) => {
		PowerPoint.run(async (context) => {
			const slides = context.presentation.slides;
			slides.load("items");

			await context.sync();

			const slideIds = slides.items.map((slide) => slide.id);
			resolve(slideIds);
		}).catch((error) => {
			reject("Error: " + error);
		});
	});
}

export async function getSlideTags(
	slideId: string
): Promise<{ slideId: string; tags: Map<string, string> }> {
	const slideTags = new Map<string, string>();
	await PowerPoint.run(async (context) => {
		context.presentation.slides.load("items");
		await context.sync();
		// get slide index of the slide with the given id
		const slideIndex = context.presentation.slides.items.findIndex(
			(slide) => slide.id === slideId
		);
		if (slideIndex === -1) {
			throw new Error(`Slide ${slideId} not found`);
		}
		const slide = context.presentation.slides.getItemAt(slideIndex);

		const tags = slide.tags;
		tags.load("key, value");
		await context.sync();
		tags.items.forEach((tag) => {
			slideTags.set(tag.key, tag.value);
		});
		//console.log("***** getting tags");
		for (let i = 0; i < slide.tags.items.length; i++) {
			console.log(
				"**********************got key " +
					JSON.stringify(slide.tags.items[i].key) +
					" with value " +
					JSON.stringify(slide.tags.items[i].value)
			);
		}
	});
	return { slideId, tags: slideTags };
}

export function base64stringToBlob(base64String: string, contentType = "") {
	// Decode the base64 string to a Uint8Array
	const binaryString = window.atob(base64String);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	// Create a Blob from the Uint8Array
	return new Blob([bytes], { type: contentType });
}

export const getSlideAsBase64 = async (slideIndex: number): Promise<string> => {
	return PowerPoint.run(async (context) => {
		const slides = context.presentation.slides;
		slides.load("items/shapes");
		await context.sync();
		const slide = slides.getItemAt(slideIndex);
		slide.load("id");
		await context.sync();
		const result = slide.exportAsBase64();
		await context.sync();
		return result.value;
	});
};

export const getSlides = async (): Promise<IPowerPointSlide[]> => {
	try {
		const slidesData: IPowerPointSlide[] = await PowerPoint.run(
			async (context) => {
				const allSlideIds = await getAllSlideIds();
				const as = context.presentation.slides;
				as.load("items");

				await context.sync();

				return as.items.map((slide, i) => {
					return {
						id: slide.id,
						title: "TODO: Fix title",
						index: i + 1,
						slideTitle: "A potential title",
						shapeCount: 0,
						shapeTypes: [], // Add the shape types to the object
					} as IPowerPointSlide;
				});
			}
		);
		return slidesData;
	} catch (error) {
		console.error(error);
		return []; // Return an empty array in case of error
	}
};

/* export async function getSlidesnew(): Promise<ISlide[]> {
	return new Promise<ISlide[]>(async (resolve, reject) => {
		await PowerPoint.run(async (context) => {
			try {
				const allSlideIds = await getAllSlideIds();
				const slidesWithTitles: any[] = [];
				const slideCount = context.presentation.slides.getCount();
				await context.sync();

				let titleFound: boolean = false;
				for (let i = 0; i < slideCount.value; i++) {
					// Get the slide at the current index
					titleFound = false;
					const slide = context.presentation.slides.getItemAt(i);
					await context.sync();

					const shapes = slide.shapes;
					// Load all the shapes in the collection without loading their properties.
					shapes.load("Name,Type");

					//const shape = shapes.getItem("Title 1");  // Assume "ShapeName" is the name of your shape
					await context.sync();
					//let title = null;

					shapes.items.forEach(async (shape) => {
						const fullSlideId = allSlideIds.find(s => s.split("#")[0] === slide.id);

						if (["Title 1", "Tittel 1"].includes(shape.name)) {
							shape.textFrame.textRange.load("text");

							await context.sync();

							slidesWithTitles.push({
								id: slide.id,
								title: shape.textFrame.textRange.text,
							});
							titleFound = true;
						}
					});
					if (titleFound === false) {
						slidesWithTitles.push({
								id:fullSlideId),
								title: "Unknown Title",
							}); 
					}
				}

				resolve(slidesWithTitles);
				await context.sync();
			} catch (error) {
				reject(error);
			}
		});
	});
} */
