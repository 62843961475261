import {
  db
} from "@/services/firebase";
import { sub } from "date-fns";
import {
  onSnapshot,
  doc,
  where,
  query,
  collection
} from "firebase/firestore";
import _ from 'lodash';

const profileSubscriptions = {};
let dbUnsubscribe = null;
let throttle = false;
let oldSubscription = null;
let tenantId = null;



const ProfileAPI = () => {
  const setTenantId = (tid) => {
    tenantId = tid;
  };

  let oldSubscriptionList = [];


  const subscribeToProfile = (email, handleProfileUpdate) => {

    profileSubscriptions[email] ?
      profileSubscriptions[email].push(handleProfileUpdate) :
      (profileSubscriptions[email] = [handleProfileUpdate]);
    updateSubscription();

  };

  const unsubscribeToProfile = (email, handleProfileUpdate) => {
    console.log("Removing subscription for ", email);
    profileSubscriptions[email] = profileSubscriptions[email].filter(
      (handle) => handle !== handleProfileUpdate
    );
    //updateSubscription();
  };

  const updateSubscription = () => {
    //if (throttle) return;

    throttle = true;

    setTimeout(() => {
      const subscriptionsList = Object.keys(profileSubscriptions).sort();
      if (_.isEqual(oldSubscriptionList, subscriptionsList)) {
        return;
      }

      oldSubscriptionList = subscriptionsList;

      oldSubscription = dbUnsubscribe;
      const q = query(
        collection(db, `tenants/${tenantId}/profiles`),
        where("uid", "in", subscriptionsList)
      );

      dbUnsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.data()) {
            const data = doc.data();

            profileSubscriptions[data.uid].forEach((callback) =>
              callback(data)
            );
          }
        });
      });

      if (oldSubscription) {
        // Unsubscribe to existing db subscription
        oldSubscription();
      }

      throttle = false;
    }, 100);
  };

  return {
    setTenantId, 
    subscribeToProfile,
    unsubscribeToProfile
  };
};

export {
  ProfileAPI
};