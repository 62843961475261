import { useLayout } from "@/contexts/LayoutContext";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useEffect, useState } from "react";
import TextTransition, { presets } from 'react-text-transition';
//import { TextLoop } from "react-text-loop-next";

const auth = [
	{
		icon: "/static/gcloud3.png",
		iconHeight: 33,
		//name: 'Google Cloud'
	},
	{
		icon: "/static/azure3.png",
		iconHeight: 33,
		//name: 'Microsoft Azure'
	},
];

const TEXTS = ["Optimize teamwork","Streamline your workflow","Scale with reusable data","Gain more time for tailored IP","More client interactions","Better work-life choices","Power Operational Productivity","Increase win rate","Better Client Satisfaction"];

export const ProductFeatures:React.FC = () => {
	const {isMobile} = useLayout();
	const [index, setIndex] = useState(0);

	useEffect(() => {
	  const intervalId = setInterval(
		() => setIndex((index) => index + 1),
		2000, // every 3 seconds
	  );
	  return () => clearTimeout(intervalId);
	}, []);


	return (
		<>
			<Card elevation={6} sx={{ maxWidth: 890, p: 4, py: 6 }}>
				<Box
					sx={{
						pl: 1,
						pr: 1,
						py: 0,
						pb: 6.0,

						//border: '1px dashed grey',
						alignItems: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Button variant="outlined" size="small" disabled>
						Technical Preview
					</Button>
				</Box>
				<Typography
					color="textSecondary"
					sx={{
						mb: 16,
						fontSize: "15px",
						fontWeight: 600,
					}}
					variant="overline"
					// We're here to help you make the most of your time.
				></Typography>
				<Box></Box>
				<Typography
					color="textSecondary"
					sx={{
						mb: 0,
						mt: 6,
						fontSize: isMobile ? "14px" : "21px",
						fontWeight: 500,
					}}
					variant="overline"
				>
					Unifying Collaboration Platform
				</Typography>
				<Typography
					sx={{
						mb: 0,
						mt: 1,
						fontSize: isMobile ? "45px" : "96px",
						fontWeight: 500,
					}}
				>
					DealMaker AI
				</Typography>

				<Box></Box>
				<Box
					sx={{
						mb: 1,
						mt: 0,
					}}
				></Box>
						<Typography
							color="#006bc4"
							sx={{ fontWeight: 600 }}
							variant={isMobile ? "h6" : "h4"}
						>
							 <TextTransition springConfig={presets.default}>{TEXTS[index % TEXTS.length]}</TextTransition>
						</Typography>
			
				
				
				<Divider sx={{ mt: 12, mb: 1 }} />
				<Typography
					color="textSecondary"
					sx={{
						mt: 9,
						mb: 4,
						fontSize: isMobile ? "9px" : "15px",
						fontWeight: 500,
					}}
					variant="overline"
				>
					Collaborate | Discovery | Produce Content
				</Typography>
				<Divider sx={{ mt: 1, mb: 9 }} />
				<Box></Box>
				<Box
					sx={{
						mb: 3,
						mt: 3,
						fontWeight: 600,
					}}
				></Box>
				<Typography
					color="textSecondary"
					sx={{
						mb: 2,
						mt: 6,
						fontWeight: 600,
					}}
					variant="overline"
				>
					Auth and granular access services
				</Typography>
				<Typography
					color="textSecondary"
					sx={{ mb: 2 }}
					variant="body2"
				></Typography>
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						"& img": {
							ml: 0,
						},
					}}
				>
					{auth.map((item, index) => (
						<Box
							key={index}
							sx={{
								alignItems: "center",
								display: "flex",
								"& + &": {
									ml: 6,
								},
							}}
						>
							<Box>
								<img
									src={item.icon}
									style={{
										maxHeight: isMobile ? "25px" : "33px",
									}}
								/>
							</Box>
						</Box>
					))}
				</Box>
				<Box
					sx={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<Typography
						color="textSecondary"
						sx={{
							mt: 9,
							mb: 3,
							fontSize: isMobile ? "9px" : "12px",
							fontWeight: 500,
						}}
						variant="overline"
					>
						SoftCap AS | 2023
					</Typography>
				</Box>
			</Card>
		</>
	);
};
