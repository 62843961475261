import React, { useEffect } from "react";
import { Project } from "@/types/project";
import { useState } from "react";
import { ProjectTimeline } from "@/hooks/useProjectTimeline";

interface ProjectsContextType {
	projects: ProjectTimeline[];
    updateProject: (project: ProjectTimeline) => void;
    removeProject: (id:string) => void;
	// Other context functions...
}

export const ProjectsContext = React.createContext<ProjectsContextType>({
	projects: [],
	updateProject: () => {},
    removeProject: () => {},
	// Other context functions...
});

export const useGlobalProjects = () => React.useContext(ProjectsContext);



export const ProjectsProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [projects, setProjects] = useState<ProjectTimeline[]>([]);

    useEffect(() => {
        console.log("GLOB projects", projects);
    }, [projects])


    const updateProject = (updatedProject: ProjectTimeline) => {
        console.log("UPDATING PROJECT", updatedProject);
        setProjects((prevProjects) => {
            const projectExists = prevProjects.some(
                (project) => project?.project.id === updatedProject.project.id
            );
            if (projectExists) {
                return prevProjects.map((project) =>
                    project.project.id === updatedProject?.project.id ? updatedProject : project
                );
            } else {
                return [...prevProjects, updatedProject];
            }
        });
    };

	const removeProject = (id:string) => {
		setProjects((prevProjects) =>
			prevProjects.filter((project) => project.project.id !== id)
		);
	};


	return (
		<ProjectsContext.Provider
			value={{ projects, updateProject, removeProject }}
		>
			{children}
		</ProjectsContext.Provider>
	);
};
