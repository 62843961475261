import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	ReactNode,
} from "react";

// Define a type for the settings object
type Settings = {
	direction: "ltr" | "rtl";
	language: string;
	pinSidebar: boolean;
	theme: "light" | "dark" | "stealth";
};

// Define a type for the context value
type SettingsContextType = {
	settings: Settings;
	saveSettings: (updatedSettings: Settings) => void;
};

const initialSettings: Settings = {
	direction: "ltr",
	language: "en",
	pinSidebar: false,
	theme: "light",
};

export const restoreSettings = (): Settings => {
	let settings = initialSettings;

	try {
		const storedData = window.localStorage.getItem("settings");

		if (storedData) {
			const parsedSettings = JSON.parse(storedData);
			if (["light", "dark", "stealth"].includes(parsedSettings.theme)) {
				settings = parsedSettings as Settings;
			} else {
				console.warn(
					new Error(`The theme ${parsedSettings.theme} is not valid`)
				);
			}
		} else {
			const prefersDarkMode = window.matchMedia(
				"(prefers-color-scheme: dark)"
			).matches;
			settings = {
				...initialSettings,
				theme: prefersDarkMode ? "dark" : "light",
			};
		}
	} catch (err) {
		console.error(err);
	}

	return settings;
};

export const storeSettings = (settings: Settings) => {
	window.localStorage.setItem("settings", JSON.stringify(settings));
};

export const SettingsContext = createContext<SettingsContextType>({
	settings: initialSettings,
	saveSettings: () => {},
});

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [settings, setSettings] = useState<Settings>(initialSettings);

	useEffect(() => {
		const restoredSettings = restoreSettings();
		setSettings(restoredSettings);
	}, []);

	const saveSettings = (updatedSettings: Settings) => {
		setSettings(updatedSettings);
		storeSettings(updatedSettings);
	};

	return (
		<SettingsContext.Provider value={{ settings, saveSettings }}>
			{children}
		</SettingsContext.Provider>
	);
};

export const useSettings = () => useContext(SettingsContext);
