import {
	OfficeThemeLatest,
	getIdBootstrapToken,
} from "@/utils/office-js-utils";
import React, { useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import { getAccessToken as getAccessTokenBE } from "@/services/firebase";

interface OfficeClientContext {
	client?: Office.HostType;
	isAddin: boolean;
	clientTheme?: OfficeThemeLatest;
	getAccessToken: () => Promise<string>;
}

const officeClientContext = createContext<OfficeClientContext | null>(null);

export function useOfficeContext() {
	return useContext(officeClientContext);
}

const OfficeContextProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [officeContext, setOfficeContext] =
		useState<OfficeClientContext | null>();
	const [isOfficeInitialized, setIsOfficeInitialized] = useState(false);
	const bootstrapTokenPromiseRef = useRef<Promise<string> | null>(null);
	const bootstrapTokenRef = useRef<
		{ token: string; expireDateInSeconds: number } | undefined
	>(null);
	const addinAccessTokenRef = useRef<
		{ token: string; expireDateInSeconds: number } | undefined
	>(null);
	const addinTokenPromiseRef = useRef<Promise<string> | null>(null);

	useEffect(() => {
		Office.onReady((info) => {
			console.log(
				"OnReady returned with info: " + JSON.stringify(info, null, 2)
			);

			const clientTheme = Office.context.officeTheme as OfficeThemeLatest;
			console.log(
				"#TADA2 OfficeContextProvider clientTheme: ",
				clientTheme
			);

			if (info.host) {
				const client = Office.context.diagnostics.host;
				const isAddin =
					client === Office.HostType.PowerPoint ||
					client === Office.HostType.Outlook ||
					client === Office.HostType.Excel ||
					client === Office.HostType.Word;
				setOfficeContext({
					client,
					isAddin,
					clientTheme,
					getAccessToken,
				});
			} else {
				setOfficeContext({
					isAddin: false,
					getAccessToken,
				});
			}
		});
	}, []);

	const getBootstrapToken = async () => {
		const getAndSetBootstrapToken = async () => {
			console.log("**********Getting new bootstrap token *************");
			const bootstrapToken = await getIdBootstrapToken();
			console.log("Got bootstrap token", bootstrapToken);
			const now = Math.floor(Date.now() / 1000);
			bootstrapTokenRef.current = {
				token: bootstrapToken,
				expireDateInSeconds: now + 3 * 60,
			};
			bootstrapTokenPromiseRef.current = null; // Reset the promise
			return bootstrapToken;
		};

		const now = Math.floor(Date.now() / 1000);
		console.log(
			"#########Checking if bootstrap token is valid########",
			bootstrapTokenRef.current?.expireDateInSeconds,
			now
		);

		if (
			bootstrapTokenRef?.current &&
			now <= bootstrapTokenRef.current.expireDateInSeconds
		) {
			console.log("#########Using existing bootstrap token########");
			return bootstrapTokenRef.current.token;
		} else {
			console.log(
				"#########No valid bootstrap token - getting token ########"
			);
			if (!bootstrapTokenPromiseRef.current) {
				// Create a new promise and set it in the state
				console.log(
					"######## No pending request in progress - creating new promise ########"
				);
				const newPromise = getAndSetBootstrapToken();
				bootstrapTokenPromiseRef.current = newPromise;
				return newPromise;
			} else {
				// Wait for the existing promise
				console.log(
					"#############Waiting for existing promise########"
				);
				return bootstrapTokenPromiseRef.current;
			}
		}
	};

	const getAccessToken = async (): Promise<string> => {
		const getAndSetAccessToken = async () => {
			const now = Math.floor(Date.now() / 1000);
			const bootstrapToken = await getBootstrapToken();
			const result: any = await getAccessTokenBE({
				authorization: bootstrapToken,
				EMULATORS: window.location.host === "localhost:3000",
			});
			console.log("Got access token", result.data);

			if (result.data.error) {
				throw new Error(result.data.error_description);
			} else {
				console.log(
					"#BABA Token expires in",
					result.data.expires_in,
					"seconds"
				);
				addinAccessTokenRef.current = {
					token: result.data.access_token,
					expireDateInSeconds: now + Number(300),
					//now + Number(120) - 60,
				};
				addinTokenPromiseRef.current = null; // Reset the promise
				return result.data.access_token;
			}
		};

		const now = Math.floor(Date.now() / 1000);
		if (
			addinAccessTokenRef?.current &&
			now <= addinAccessTokenRef.current.expireDateInSeconds
		) {
			/*console.log(
				"#########Using existing access token########",
				Math.floor(Date.now() / 1000),
				addinAccessTokenRef.current
			); */
			return addinAccessTokenRef.current.token;
		} else {
			if (!addinTokenPromiseRef.current) {
				// Create a new promise and set it in the state
				console.log(
					"######## No pending request in progress - creating new promise ########"
				);
				const newPromise = getAndSetAccessToken();
				addinTokenPromiseRef.current = newPromise;
				return newPromise;
			} else {
				// Wait for the existing promise
				console.log(
					"#############Waiting for existing promise########"
				);
				return addinTokenPromiseRef.current;
			}
		}
	};

	if (!officeContext) return <></>;
	return (
		<officeClientContext.Provider value={officeContext}>
			{children}
		</officeClientContext.Provider>
	);
};

export default OfficeContextProvider;
