import { useState, useEffect, useMemo } from "react";
//import { firestore } from 'firebase/app';
import {
	QuerySnapshot,
	query,
	CollectionReference,
	onSnapshot,
	QueryConstraint,
	DocumentReference,
	DocumentData,
	Query,
	Timestamp,
	doc,
	deleteDoc,
	setDoc,
	FirestoreError,
	QueryCompositeFilterConstraint,
	limit,
} from "firebase/firestore";
import { collection, collectionGroup } from "firebase/firestore";
import { db } from "../services/firebase";
import { useAuth } from "@/contexts/auth-context";

class CollectionData {
	id?: string;
	ref?: DocumentReference<CollectionData>;
}

export function convertTimestampsToDates(doc: any) {
	if (doc instanceof Timestamp) {
		return doc.toDate();
	} else if (doc instanceof Object) {
		for (const [key, value] of Object.entries(doc)) {
			if (!(key === "ref"))
				doc[key] = convertTimestampsToDates(value);
		}
	}
	return doc;
}

export interface Col<T> {
	docs: T[] | undefined;
	loading: boolean;
	error: boolean;
	setQueryConstraints: React.Dispatch<
		React.SetStateAction<QueryConstraint | QueryCompositeFilterConstraint>
	>;
	deleteDocument: (docId: string) => Promise<void>;
}

class WithCustomJSON<T> {
	constructor(private original: T) {}
  
	toJSON() {
	  const { ref, ...serializable } = this.original as any;
	  // Custom serialization logic here
	  return serializable;
	}
  }

  
function generateWhereSignature(...args: any[]): string {
	return JSON.stringify(args);
  }
  

export function useCollection<T extends CollectionData>(
	collectionName: string,
	constraints?: QueryConstraint | QueryCompositeFilterConstraint,
	options?: { global?: boolean; converter?: (doc: T) => T }
): Col<T> {
	const { profile } = useAuth();
	const [qConstraints, setQueryConstraints] = useState<
		QueryConstraint | QueryCompositeFilterConstraint
	>();

	const querySignature = generateWhereSignature(constraints);
	const memoizedQuery = useMemo(() => constraints, [querySignature]);
	//const queryConstraints = useMemo(() => where("entityType", "==", entityTypeMapping[entityType]), [entityType]);

	//console.log("======== useCollection", collectionName, constraints, querySignature, memoizedQuery);
	
	const [colState, setColState] = useState<Col<T>>({
		docs: [],
		loading: true,
		error: false,
		setQueryConstraints
	} as Col<T>);

	const handleError = (error: FirestoreError) => {
		console.error(
			"Unable to listen to collection " + collectionName,
			error
		);
		setColState((prevState) => ({
			...prevState,
			loading: false,
			error: true,
		}));
	};

	const processSnapshot = (snapshot: QuerySnapshot) => {
		let updatedDocs = snapshot.docs.map((doc) => {
			let data = { ...doc.data() } as { [key: string]: any }; // Map<string, any>;



			convertTimestampsToDates(data);



		/*	return { ...(<T>data),id: doc.id, toJSON: () => { 
				 const { ref, ...data } = data as any;
			*/	
				return { ...(<T>data), id: doc.id };
		}); 

		if (options?.converter) updatedDocs = (updatedDocs.map(options.converter) as any);

		setColState((prevState) => ({
			...prevState,
			loading: false,
			error: false,
			docs: updatedDocs,
		}));
	};

	const setupSubscription = (path: string) => {
		setColState((prevState) => ({ ...prevState, loading: true }));

		let unsubscribe: () => void;
		let col: CollectionReference<DocumentData>;

		col = collection(db, path); //as CollectionReference<T>;

		//setColState(...colState, {loading:true} as Col<T>);
		//setLoading(true);

		if (qConstraints) {
			unsubscribe = onSnapshot(
				query(col, qConstraints as QueryConstraint),
				processSnapshot,
				(error) => handleError(error)
			);
		} else if (constraints) {
			//console.log("we are here", collectionName, constraints);
			unsubscribe = onSnapshot(
				query(col, constraints as QueryConstraint),
				processSnapshot,
				(error) => handleError(error)
			);
		} else {
			unsubscribe = onSnapshot(col, processSnapshot, (error) =>
				handleError(error)
			);
		}

		return unsubscribe;
	};

	useEffect(() => {
		if (!profile && !options?.global) return;
		
		const path = options?.global
			? collectionName
			: `tenants/${profile.tid}/${collectionName}`;

		//setCol(useCollectionInt<T>(`tenants/${profile.tid}/${collectionName}`,
		//constraints));

		return setupSubscription(path);
	}, [profile,  memoizedQuery, collectionName]);

	/* const col = useCollectionInt<T>(
		`tenants/${profile.tid}/${collectionName}`,
		constraints
	); */

	/* if (converter) {
		col.docs = col.docs.map(converter);
	}*/

	return colState;
}

export function useCollectionGlobal<T extends CollectionData>(
	collectionName: string,
	constraints?: QueryConstraint | QueryCompositeFilterConstraint,
	converter?: (doc: T) => T
) {
	const col = useCollectionInt<T>(collectionName, constraints);

	if (converter) {
		col.docs = col.docs.map(converter);
	}

	return col;
}

/*
export function useCollectionGroup<T extends CollectionData>(
	collectionName: string,
	constraints?: QueryConstraint | Array<QueryConstraint> | QueryCompositeFilterConstraint,
	converter?: (doc: T) => T
) {
	const col = useCollectionInt<T>(collectionName, true, constraints);

	if (converter) {
		col.docs = col.docs.map(converter);
	}

	return col;
} */

function useCollectionInt<T extends CollectionData>(
	collectionName: string,
	constraints?:
		| QueryConstraint
		| Array<QueryConstraint>
		| QueryCompositeFilterConstraint
) {
	const [docs, setDocs] = useState<T[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<boolean>(false);
	const [qConstraints, setQueryConstraints] = useState<
		QueryConstraint | QueryCompositeFilterConstraint
	>();

	const handleError = (error: FirestoreError) => {
		console.error(
			"Unable to listen to collection " + collectionName,
			error
		);
		setError(true);
		setLoading(false);
	};

	useEffect(() => {
		let unsubscribe: () => void;

		let col: CollectionReference<DocumentData>;

		col = collection(db, collectionName); //as CollectionReference<T>;

		setLoading(true);

		if (qConstraints) {
			unsubscribe = onSnapshot(
				query(col, qConstraints as QueryConstraint),
				processSnapshot,
				(error) => handleError(error)
			);
		} else if (constraints) {
			console.log("we are here", collectionName, constraints);
			unsubscribe = onSnapshot(
				query(col, constraints as QueryConstraint),
				processSnapshot,
				(error) => handleError(error)
			);
		} else {
			unsubscribe = onSnapshot(col, processSnapshot, (error) =>
				handleError(error)
			);
		}

		return unsubscribe;
	}, [collectionName, qConstraints]);

	const processSnapshot = (snapshot: QuerySnapshot) => {
		const updatedDocs = snapshot.docs.map((doc) => {
			const data = { ...doc.data() } as { [key: string]: any }; // Map<string, any>;

			function convertTimestampsToDates(doc: any) {
				if (doc instanceof Timestamp) {
					return doc.toDate();
				} else if (doc instanceof Object) {
					for (const [key, value] of Object.entries(doc)) {
						if (!(key === "ref"))
							doc[key] = convertTimestampsToDates(value);
					}
				}
				return doc;
			}

			convertTimestampsToDates(data);


			return { ...(<T>data), id: doc.id, ref:doc.ref };
		});

		setDocs(updatedDocs);
		setLoading(false);
	};

	const deleteDocument = async (docId: string): Promise<void> => {
		return await deleteDoc(doc(db, collectionName, docId));
	};

	async function updateDocument<T extends CollectionData>(doc: T) {
		const { ref, ...docWithoutRef } = doc;
		// Update the document in firestore
		await setDoc(ref, docWithoutRef, { merge: true });
	}

	async function addDocument<T extends CollectionData>(newDoc: T) {
		const { ref, ...docWithoutRef } = newDoc;
		const docRef = doc(collection(db, collectionName));
		await setDoc(docRef, {
			...docWithoutRef,
		});
	}

	return {
		docs,
		loading,
		setQueryConstraints,
		deleteDocument,
		updateDocument,
		addDocument,
	};
}
