// LockScreen.tsx
import React from "react";
import {
	Backdrop,
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	useTheme,
} from "@mui/material";

export const NoConnection: React.FC = () => {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "100vh",
				}}
			>
				<Card variant="outlined" sx={{ width: "20vw" }}>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						sx={{ mt: "21px" }}
					>
						<img
							src="/static/icon-192x192.png"
							style={{
								height: "60px",
							}}
						/>

						<Typography
							variant="h5"
							sx={{ mt: 3, mb: 3 }}
							align="center"
						>
							No internet connection
						</Typography>
					</Box>
				</Card>
			</Box>
		</>
	);
};
