// LockScreen.tsx
import React from "react";
import {
	Backdrop,
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	useTheme,
} from "@mui/material";
import { useLockScreen } from "@/contexts/LockScreenContext";

export const LockScreen: React.FC = () => {
	const { isLocked, unlock } = useLockScreen();
	const theme = useTheme();

	return isLocked ? (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: 1000000000,
				backdropFilter: "blur(15px)",
			}}
			open={isLocked}
		>
			<Card variant="outlined">
				<CardContent>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						sx={{
							//borderRadius: "3px",
							width: "20vw",
							height: "20vh",
							//backgroundColor: theme.palette.background.paper,
						}}
					>
						<img
							src="/static/icon-192x192.png"
							style={{
								height: "90px",
								//filter: "grayscale(100%) contrast(0%)",
								animation: "blurIncrease 1s ease-out infinite",
							}}
						/>

						<Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
							Locked
						</Typography>
						<Button
							onClick={unlock}
							color="warning"
							variant="outlined"
							//sx={{ mt: 2 }}
						>
							Unlock
						</Button>
					</Box>
				</CardContent>
			</Card>
		</Backdrop>
	) : null;
};
