import React, { useContext } from "react";

import {
	alpha,
	createTheme as createMuiTheme,
	responsiveFontSizes,
} from "@mui/material/styles";
import {
	darkBackground,
	darkError,
	darkInfo,
	darkNeutral,
	darkPrimary,
	darkSecondary,
	darkSuccess,
	darkText,
	darkWarning,
	darkDrawerdmai,
	lightBackground,
	lightError,
	lightInfo,
	lightNeutral,
	lightPrimary,
	lightSecondary,
	lightSuccess,
	lightText,
	lightWarning,
	lightDrawerdmai,
	stealthBackground,
	stealthError,
	stealthInfo,
	stealthNeutral,
	stealthPrimary,
	stealthSecondary,
	stealthSuccess,
	stealthText,
	stealthWarning,
	stealthDrawerdmai,
} from "./colors";
import { XCircle as XCircleIcon } from "../icons/x-circle";
import { lighten } from "@mui/system";

const { breakpoints } = createMuiTheme();

const baseThemeOptions = {
	components: {
		MUIRichTextEditor: {
			root: {
				marginTop: 0,
				width: "80%",
			},
			editor: {
				borderBottom: "1px solid gray",
			},
		},

		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"&.Mui-focused": {
						backgroundColor: "transparent",
					},
					"& fieldset": {
						//borderColor: '#A9B6E5'
					},
					"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
						borderColor: "inherit",
					},
					"&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "inherit",
					},
					":hover .MuiOutlinedInput-notchedOutline": {
						// borderColor: '#046CCC'
						borderColor: "#007fff",
					},
					"&.Mui-focused fieldset": {
						borderWidth: "1px",
					},
				},
			},
		},

		MuiAutocomplete: {
			defaultProps: {
				size: "small",
			},
			styleOverrides: {
				noOptions: {
					fontSize: 14,
					//letterSpacing: 0.15,
					lineHeight: 1.6,
				},
				option: {
					fontSize: 14,
					letterSpacing: 0,
					lineHeight: 1.6,
				},
				paper: {
					boxShadow: "none",
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 500,
					letterSpacing: 0,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				sizeLarge: {
					fontSize: 15,
				},
				sizeMedium: {
					fontSize: 14,
				},
				sizeSmall: {
					fontSize: 13,
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: false,
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: 4,
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					paddingBottom: 16,
					paddingLeft: 24,
					paddingRight: 24,
					paddingTop: 16,
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					paddingBottom: 20,
					paddingLeft: 24,
					paddingRight: 24,
					paddingTop: 20,
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					paddingBottom: 6,
					paddingLeft: 24,
					paddingRight: 24,
					paddingTop: 9,
				},
				subheader: {
					fontSize: 14,
				},
				title: {
					fontSize: 14,
					textTransform: "uppercase",
					//letterSpacing: 0.9,
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				checkedIcon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="4"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="2"
							fill="currentColor"
						/>
						<path
							d="M13.6666 6.0835L7.24992 12.5002L4.33325 9.5835"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				),
				indeterminateIcon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14 0H4C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0Z"
							fill="currentColor"
						/>
						<path
							d="M13.6666 9H5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				),
			},
			// styleOverrides: {
			//   root: {
			//     transition: 'color 250ms',
			//     ':hover, &.Mui-checked:hover, &.MuiCheckbox-indeterminate:hover': {
			//       backgroundColor: 'transparent'
			//     }
			//   }
			// }
		},
		MuiChip: {
			defaultProps: {
				deleteIcon: <XCircleIcon />,
			},
			styleOverrides: {
				avatar: {
					borderRadius: 6,
				},
				root: {
					borderRadius: 6,
					fontWeight: 400,
					letterSpacing: 0,
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					height: "100%",
					overflowY: "scroll",
				},
				body: {
					height: "100%",
				},
				"& #root": {
					height: "100%",
				},
				"& #nprogress .bar": {
					zIndex: 2000,
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					paddingBottom: 32,
					paddingLeft: 32,
					paddingRight: 32,
					paddingTop: 24,
					"&>:not(:first-of-type)": {
						marginLeft: 16,
					},
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					paddingBottom: 8,
					paddingLeft: 32,
					paddingRight: 32,
					paddingTop: 8,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontSize: 24,
					fontWeight: 600,
					paddingBottom: 24,
					paddingLeft: 32,
					paddingRight: 32,
					paddingTop: 32,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: 14,
					//letterSpacing: 0.15,
					lineHeight: 1.43,
				},
			},
		},
		MuiIcon: {
			styleOverrides: {
				fontSizeLarge: {
					fontSize: 32,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: 3,
					padding: 8,
				},
				sizeSmall: {
					padding: 4,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				button: {
					"&:before": {
						borderRadius: "0px 2px 2px 0px",
						bottom: 0,
						content: '""',
						left: 0,
						position: "absolute",
						top: 0,
						transform: "scaleX(0)",
						transformOrigin: "left center",
						transition: "transform 0.25s",
						width: 4,
					},
					"&:active:before": {
						transform: "scaleX(1)",
					},
				},
				dense: {
					paddingBottom: 6,
					paddingLeft: 16,
					paddingRight: 16,
					paddingTop: 6,
				},
			},
		},
		MuiListItemText: {
			defaultProps: {
				primaryTypographyProps: {
					variant: "body2",
				},
			},
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					backgroundColor: "transparent",
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 400,
					//letterSpacing: 0.15,
					lineHeight: 1.43,
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					transition: "color 250ms",
					":hover, &.Mui-checked:hover": {
						backgroundColor: "transparent",
					},
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				size: "small",
			},
			styleOverrides: {
				filled: {
					"&:focus": {
						backgroundColor: "transparent",
					},
				},
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					borderRadius: 4,
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				fontSizeLarge: {
					fontSize: 32,
				},
			},
		},

		// MuiSwitch: {
		//   styleOverrides: {
		//     root: {
		//       borderRadius: 48,
		//       height: 24,
		//       marginBottom: 8,
		//       marginLeft: 8,
		//       marginRight: 8,
		//       marginTop: 8,
		//       padding: 0,
		//       width: 44
		//     },
		//     switchBase: {
		//       padding: 4,
		//       '&:hover': {
		//         backgroundColor: 'transparent'
		//       },
		//       '&.Mui-checked+.MuiSwitch-track': {
		//         opacity: 1
		//       },
		//       '&.Mui-disabled': {
		//         '&+.MuiSwitch-track': {
		//           opacity: 1
		//         }
		//       },
		//       '&.Mui-checked.Mui-disabled+.MuiSwitch-track': {
		//         opacity: 0.5
		//       }
		//     },
		//     track: {
		//       opacity: 1
		//     },
		//     thumb: {
		//       height: 16,
		//       width: 16
		//     }
		//   }
		// },
		MuiTabs: {
			styleOverrides: {
				root: {
					// Your existing styles for MuiTabs
				},
				indicator: {
					// Override the height of the indicator
					height: "4px", // Set the height you want here
					borderRadius: "1px",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: 16,
					fontWeight: 500,
					letterSpacing: 0,
					lineHeight: 1.5,
					[breakpoints.up("sm")]: {
						marginLeft: 16,
						marginRight: 16,
						minWidth: "fit-content",
						paddingLeft: 0,
						paddingRight: 0,
						"&:first-of-type": {
							marginLeft: 0,
						},
					},
				},
			},
		},

		MuiTableHead: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					".MuiTableCell-root": {
						fontSize: 12,
						fontWeight: 500,
						textTransform: "uppercase",
					},
					"&:last-child .MuiTableCell-root": {
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					":last-of-type .MuiTableCell-root": {
						borderWidth: 0,
						borderBottomLeftRadius: 1,
						borderBottomRightRadius: 1,
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 400,
					paddingTop: "0.6em", // 0.3em equivalent to 0.3 in MUI's spacing system
					paddingBottom: "0.6em",
				},
			},
		},
	},
	shape: {
		borderRadius: 6,
	},
	typography: {
		//fontFamily: 'Inter, sans-serif',
		fontFamily: [
			//"Inter",
			//"Montserrat",
			//"Segoe UI",
			"Barlow",
			//"Archivo",
			//"Noto Sans",
			//"Hedvig Letters Sans",
			//"IBM Plex Sans",
			"Roboto",
			//"Helvetica Neue",
			//"Barlow",
			//"Roboto regular",
			//"Helvetica",
			"Arial",
			"sans-serif",
		].join(","),

		h1: {
			fontSize: 48,
			fontWeight: 500,
			lineHeight: 1.5,
		},
		h2: {
			fontSize: 36,
			fontWeight: 500,
			lineHeight: 1.5,
		},
		h3: {
			fontSize: 32,
			fontWeight: 500,
			lineHeight: 1.5,
		},
		h4: {
			fontSize: 28,
			fontWeight: 500,
			lineHeight: 1.5,
		},
		h5: {
			fontSize: 18,
			fontWeight: 500,
			lineHeight: 1.5,
		},
		h6: {
			// CARD HEADERS
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.5,
		},
		body1: {
			//Default font
			fontSize: 16,
			letterSpacing: 0,
			fontWeight: 400,
		},
		body2: {
			fontSize: 14,
			lineHeight: 1.5,
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 400,
			letterSpacing: 0,
			lineHeight: 1.5,
		},
		subtitle2: {
			fontSize: 12,
			fontWeight: 500,
			letterSpacing: 0,
			//lineHeight: 1.75,
		},

		caption: {
			fontWeight: 500,
			//fontSize: 11,
			lineHeight: 1.5,
		},
		overline: {
			fontSize: 12,
			fontWeight: 500,
			//letterSpacing: 1,
			lineHeight: 1.5,
		},
		button: {
			fontWeight: 400,
			//letterSpacing: 0.6,
			textTransform: "none",
		},
	},
};

const lightThemeOptions = {
	components: {
		overrides: {
			MUIRichTextEditor: {
				root: {
					marginTop: 20,
					width: "80%",
				},
				editor: {
					borderBottom: "1px solid gray",
				},
			},
		},

		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: darkNeutral[3400],
				},
				arrow: {
					color: darkNeutral[3400],
				},
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				size: "small",
			},
			styleOverrides: {
				paper: {
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: lightNeutral[400],
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					//backgroundColor: lightNeutral[200],
					backgroundColor: "transparent",
					color: lightText.secondary,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					//sc orginal = no borderRadius
					borderRadius: 3,
					/*":focus": {
						boxShadow: `${alpha(
							lightPrimary.main,
							0.25
						)} 0 0 0 0.1rem`,
					},
					*/
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				icon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="4"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="2"
							fill={lightBackground.paper}
						/>
					</svg>
				),
			},
			styleOverrides: {
				root: {
					color: lightText.secondary,
					":hover:not(.Mui-checked)": {
						color: lightText.primary,
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				avatar: {
					color: lightNeutral[700],
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				button: {
					"&:before": {
						backgroundColor: lightPrimary.main,
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					border: `1px solid ${lightNeutral[300]}`,
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				checkedIcon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="9"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="7"
							fill="currentColor"
						/>
						<rect
							x="5"
							y="5"
							width="8"
							height="8"
							rx="4"
							fill={lightBackground.paper}
						/>
					</svg>
				),
				icon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="9"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="7"
							fill={lightBackground.paper}
						/>
					</svg>
				),
			},
			styleOverrides: {
				root: {
					color: lightText.secondary,
					":hover:not(.Mui-checked)": {
						color: lightText.primary,
					},
				},
			},
		},

		// MuiSwitch: {
		//   styleOverrides: {
		//     root: {
		//       ':focus-within': {
		//         boxShadow: `${alpha(lightPrimary.main, 0.25)} 0 0 0 0.2rem`
		//       }
		//     },
		//     switchBase: {
		//       '&.Mui-checked+.MuiSwitch-track': {
		//         backgroundColor: lightSuccess.main
		//       },
		//       '&.Mui-disabled': {
		//         '&+.MuiSwitch-track': {
		//           backgroundColor: alpha(lightText.primary, 0.08)
		//         },
		//         '.MuiSwitch-thumb': {
		//           backgroundColor: alpha(lightText.primary, 0.26)
		//         }
		//       },
		//       '&.Mui-checked.Mui-disabled+.MuiSwitch-track': {
		//         backgroundColor: lightSuccess.main
		//       }
		//     },
		//     track: {
		//       backgroundColor: lightNeutral[500]
		//     },
		//     thumb: {
		//       backgroundColor: '#ffffff'
		//     }
		//   }
		// },
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: `1px solid ${lightNeutral[200]}`,
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: lightNeutral[100],
					borderBottom: `1px solid ${lightNeutral[200]}`,

					".MuiTableCell-root": {
						color: lightText.secondary,
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.MuiTableRow-hover:hover": {
						backgroundColor: lightNeutral[100],
					},
					":hover": {
						backgroundColor: "rgba(0, 0, 0, 0.04)",
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderColor: lightNeutral[300],
					//"& .MuiSvgIcon-root": {
					//	color: alpha(lightText.primary, 0.38),
					//},
				},
			},
		},
	},
	palette: {
		action: {
			active: alpha(lightText.secondary, 0.86),
			disabled: alpha(lightText.primary, 0.26),
			disabledBackground: alpha(lightText.primary, 0.08),
			focus: alpha(lightText.primary, 0.12),
			hover: alpha(lightText.primary, 0.06),
			selected: alpha(lightText.primary, 0.08),
		},
		background: {
			default: lightBackground.default,
			paper: lightBackground.paper,
		},
		divider: lightNeutral[200],
		error: {
			contrastText: lightError.contrast,
			dark: lightError.dark,
			light: lightError.light,
			main: lightError.main,
		},
		info: {
			contrastText: lightInfo.contrast,
			dark: lightInfo.dark,
			light: lightInfo.light,
			main: lightInfo.main,
		},
		mode: "light",
		primary: {
			contrastText: lightPrimary.contrast,
			dark: lightPrimary.dark,
			light: lightPrimary.light,
			main: lightPrimary.main,
		},
		secondary: {
			contrastText: lightSecondary.contrast,
			dark: lightSecondary.dark,
			light: lightSecondary.light,
			main: lightSecondary.main,
		},
		success: {
			contrastText: lightSuccess.contrast,
			dark: lightSuccess.dark,
			light: lightSuccess.light,
			main: lightSuccess.main,
		},
		text: {
			disabled: alpha(lightText.primary, 0.38),
			primary: lightText.primary,
			secondary: lightText.secondary,
		},
		warning: {
			contrastText: lightWarning.contrast,
			dark: lightWarning.dark,
			light: lightWarning.light,
			main: lightWarning.main,
		},

		neutral: lightNeutral,
		drawerdmai: {
			contrastText: lightDrawerdmai.contrast,
			dark: lightDrawerdmai.dark,
			light: lightDrawerdmai.light,
			main: lightDrawerdmai.main,
		},
	},
	shadows: [
		"none",
		"0px 1px 2px rgba(9, 30, 66, 0.2)",
		"0px 1px 3px rgba(9, 30, 66, 0.12)",
		"0px 2px 4px rgba(9, 30, 66, 0.08)",
		"0px 3px 5px rgba(9, 30, 66, 0.08)",
		"0px 3px 5px -1px rgba(9, 30, 66, 0.08)",
		"0px 5px 7px rgba(9, 30, 66, 0.08)",
		"0px 6px 8px rgba(9, 30, 66, 0.08)",
		"0px 8px 12px rgba(9, 30, 66, 0.08)",
		"0px 9px 14px rgba(9, 30, 66, 0.08)",
		"0px 10px 16px rgba(9, 30, 66, 0.08)",
		"0px 11px 18px rgba(9, 30, 66, 0.08)",
		"0px 12px 20px rgba(9, 30, 66, 0.08)",
		"0px 13px 22px rgba(9, 30, 66, 0.08)",
		"0px 14px 24px rgba(9, 30, 66, 0.08)",
		"0px 15px 26px rgba(9, 30, 66, 0.08)",
		"0px 18px 28px rgba(9, 30, 66, 0.08)",
		"0px 20px 30px rgba(9, 30, 66, 0.08)",
		"0px 22px 32px rgba(9, 30, 66, 0.08)",
		"0px 24px 34px rgba(9, 30, 66, 0.08)",
		"0px 26px 36px rgba(9, 30, 66, 0.08)",
		"0px 28px 38px rgba(9, 30, 66, 0.08)",
		"0px 30px 40px rgba(9, 30, 66, 0.08)",
		"0px 32px 42px rgba(9, 30, 66, 0.08)",
		"0px 36px 46px rgba(9, 30, 66, 0.12)",
	],
};

const darkThemeOptions = {
	components: {
		defaultProps: {
			size: "small",
		},
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: darkNeutral[400],
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: darkNeutral[3400],
				},
				arrow: {
					color: darkNeutral[3400],
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					//backgroundColor: darkNeutral[200],
					backgroundColor: "transparent",
					color: darkText.secondary,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					//sc orginal = no borderRadius
					borderRadius: 3,
					/*":hover": {
						boxShadow: `${alpha(
							lightPrimary.main,
							0.25
						)} 0 0 0 0.1rem`,
					},
					*/
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				icon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="4"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="2"
							fill={darkBackground.paper}
						/>
					</svg>
				),
			},
			styleOverrides: {
				root: {
					color: darkText.secondary,
					":hover:not(.Mui-checked)": {
						color: darkText.primary,
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				avatar: {
					color: darkNeutral[700],
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				button: {
					"&:before": {
						backgroundColor: darkPrimary.main,
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					border: `1px solid ${darkNeutral[300]}`,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				checkedIcon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="9"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="7"
							fill="currentColor"
						/>
						<rect
							x="5"
							y="5"
							width="8"
							height="8"
							rx="4"
							fill={darkBackground.paper}
						/>
					</svg>
				),
				icon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="9"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="7"
							fill={darkBackground.paper}
						/>
					</svg>
				),
			},
			styleOverrides: {
				root: {
					color: darkText.secondary,
					":hover:not(.Mui-checked)": {
						color: darkText.primary,
					},
				},
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: darkNeutral[100],
				},
			},
		},
		// MuiSwitch: {
		//   styleOverrides: {
		//     root: {
		//       ':focus-within': {
		//         boxShadow: `${alpha(lightPrimary.main, 0.25)} 0 0 0 0.2rem`
		//       }
		//     },
		//     switchBase: {
		//       '&.Mui-checked+.MuiSwitch-track': {
		//         backgroundColor: darkSuccess.main
		//       },
		//       '&.Mui-disabled': {
		//         '&+.MuiSwitch-track': {
		//           backgroundColor: alpha(lightText.primary, 0.08)
		//         },
		//         '.MuiSwitch-thumb': {
		//           backgroundColor: alpha(lightText.primary, 0.26)
		//         }
		//       },
		//       '&.Mui-checked.Mui-disabled+.MuiSwitch-track': {
		//         backgroundColor: darkSuccess.main
		//       }
		//     },
		//     track: {
		//       backgroundColor: darkNeutral[500]
		//     },
		//     thumb: {
		//       backgroundColor: '#ffffff'
		//     }
		//   }
		// },

		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: `1px solid ${darkNeutral[100]}`,
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: darkNeutral[100],
					borderBottom: `1px solid ${darkNeutral[200]}`,
					".MuiTableCell-root": {
						color: darkText.secondary,
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.MuiTableRow-hover:hover": {
						backgroundColor: darkNeutral[3101],
					},
					":hover": {
						backgroundColor: lighten(darkNeutral[3101], 0.06),
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderColor: darkNeutral[300],
					//"& .MuiSvgIcon-root": {
					//	color: alpha(darkText.primary, 0.38),
					//},
				},
			},
		},
	},
	palette: {
		action: {
			active: alpha(darkText.secondary, 0.86),
			disabled: alpha(darkText.primary, 0.26),
			disabledBackground: alpha(darkText.primary, 0.08),
			focus: alpha(darkText.primary, 0.12),
			hover: alpha(darkText.primary, 0.06),
			selected: alpha(darkText.primary, 0.08),
		},
		background: {
			default: darkBackground.default,
			paper: darkBackground.paper,
		},
		divider: darkNeutral[200],
		error: {
			contrastText: darkError.contrast,
			dark: darkError.dark,
			light: darkError.light,
			main: darkError.main,
		},
		info: {
			contrastText: darkInfo.contrast,
			dark: darkInfo.dark,
			light: darkInfo.light,
			main: darkInfo.main,
		},
		mode: "dark",
		primary: {
			contrastText: darkPrimary.contrast,
			dark: darkPrimary.dark,
			light: darkPrimary.light,
			main: darkPrimary.main,
		},
		secondary: {
			contrastText: darkSecondary.contrast,
			dark: darkSecondary.dark,
			light: darkSecondary.light,
			main: darkSecondary.main,
		},
		success: {
			contrastText: darkSuccess.contrast,
			dark: darkSuccess.dark,
			light: darkSuccess.light,
			main: darkSuccess.main,
		},
		text: {
			disabled: alpha(darkText.primary, 0.38),
			primary: darkText.primary,
			secondary: darkText.secondary,
		},
		warning: {
			contrastText: darkWarning.contrast,
			dark: darkWarning.dark,
			light: darkWarning.light,
			main: darkWarning.main,
		},

		neutral: darkNeutral,
		drawerdmai: {
			contrastText: darkDrawerdmai.contrast,
			dark: darkDrawerdmai.dark,
			light: darkDrawerdmai.light,
			main: darkDrawerdmai.main,
		},
	},
	shadows: [
		"none",
		"0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
		"0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
		"0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
		"0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
		"0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
		"0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
		"0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
		"0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
		"0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
		"0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
		"0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
		"0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
		"0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
		"0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
		"0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
		"0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
		"0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
		"0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
		"0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
		"0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
	],
};

const stealthThemeOptions = {
	components: {
		MuiAutocomplete: {
			defaultProps: {
				size: "small",
			},
			styleOverrides: {
				paper: {
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: stealthNeutral[400],
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: stealthNeutral[3400],
				},
				arrow: {
					color: stealthNeutral[3400],
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					//backgroundColor: darkNeutral[200],
					backgroundColor: "transparent",
					color: stealthText.secondary,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					//sc orginal = no borderRadius
					borderRadius: 3,
					/*":focus": {
						boxShadow: `${alpha(
							lightPrimary.main,
							0.25
						)} 0 0 0 0.1rem`,
					},
					*/
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
					borderColor: stealthBackground.paper,
					backgroundColor: stealthBackground.default,
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					backgroundColor: stealthBackground.paper,
				},
				title: {
					color: stealthText.secondary,
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				icon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="4"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="2"
							fill={stealthBackground.paper}
						/>
					</svg>
				),
			},
			styleOverrides: {
				root: {
					color: stealthText.secondary,
					":hover:not(.Mui-checked)": {
						color: stealthText.primary,
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				avatar: {
					color: stealthNeutral[700],
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				button: {
					"&:before": {
						backgroundColor: stealthPrimary.main,
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					border: `1px solid ${stealthNeutral[300]}`,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				checkedIcon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="9"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="7"
							fill="currentColor"
						/>
						<rect
							x="5"
							y="5"
							width="8"
							height="8"
							rx="4"
							fill={stealthBackground.paper}
						/>
					</svg>
				),
				icon: (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							width="18"
							height="18"
							rx="9"
							fill="currentColor"
						/>
						<rect
							x="2"
							y="2"
							width="14"
							height="14"
							rx="7"
							fill={stealthBackground.paper}
						/>
					</svg>
				),
			},
			styleOverrides: {
				root: {
					color: stealthText.secondary,
					":hover:not(.Mui-checked)": {
						color: stealthText.primary,
					},
				},
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: stealthNeutral[100],
				},
			},
		},

		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: `1px solid ${stealthNeutral[100]}`,
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: stealthNeutral[100],
					borderBottom: `1px solid ${stealthNeutral[200]}`,
					".MuiTableCell-root": {
						color: stealthText.secondary,
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.MuiTableRow-hover:hover": {
						backgroundColor: stealthNeutral[3101],
					},
					":hover": {
						backgroundColor: lighten(stealthNeutral[3101], 0.06),
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderColor: stealthNeutral[300],
					//"& .MuiSvgIcon-root": {
					//	color: alpha(stealthText.primary, 0.38),
					//},
				},
			},
		},
	},
	palette: {
		action: {
			active: alpha(stealthText.secondary, 0.86),
			disabled: alpha(stealthText.primary, 0.26),
			disabledBackground: alpha(stealthText.primary, 0.08),
			focus: alpha(stealthText.primary, 0.12),
			hover: alpha(stealthText.primary, 0.06),
			selected: alpha(stealthText.primary, 0.08),
		},
		background: {
			default: stealthBackground.default,
			paper: stealthBackground.paper,
		},
		divider: stealthNeutral[200],
		error: {
			contrastText: stealthError.contrast,
			dark: stealthError.dark,
			light: stealthError.light,
			main: stealthError.main,
		},
		info: {
			contrastText: stealthInfo.contrast,
			dark: stealthInfo.dark,
			light: stealthInfo.light,
			main: stealthInfo.main,
		},
		mode: "stealth",
		primary: {
			contrastText: darkPrimary.contrast,
			dark: stealthPrimary.dark,
			light: stealthPrimary.light,
			main: stealthPrimary.main,
		},
		secondary: {
			contrastText: stealthSecondary.contrast,
			dark: stealthSecondary.dark,
			light: stealthSecondary.light,
			main: stealthSecondary.main,
		},
		success: {
			contrastText: stealthSuccess.contrast,
			dark: stealthSuccess.dark,
			light: stealthSuccess.light,
			main: stealthSuccess.main,
		},
		text: {
			disabled: alpha(stealthText.primary, 0.38),
			primary: stealthText.primary,
			secondary: stealthText.secondary,
		},
		warning: {
			contrastText: stealthWarning.contrast,
			dark: stealthWarning.dark,
			light: stealthWarning.light,
			main: stealthWarning.main,
		},

		neutral: stealthNeutral,
		drawerdmai: {
			contrastText: stealthDrawerdmai.contrast,
			dark: stealthDrawerdmai.dark,
			light: stealthDrawerdmai.light,
			main: stealthDrawerdmai.main,
		},
	},
	shadows: [
		"none",
		"0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
		"0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
		"0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
		"0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
		"0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
		"0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
		"0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
		"0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
		"0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
		"0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
		"0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
		"0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
		"0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
		"0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
		"0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
		"0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
		"0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
		"0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
		"0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
		"0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
		"0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
	],
};

export const createCustomTheme = (config = {}) => {
	let themeOptions;

	switch (config.theme) {
		case "light":
			themeOptions = lightThemeOptions;
			break;
		case "dark":
			themeOptions = darkThemeOptions;
			break;
		case "stealth":
			themeOptions = stealthThemeOptions;
			break;
		default:
			console.warn(new Error(`The theme ${config.theme} is not valid`));
			themeOptions = lightThemeOptions;
	}

	//console.log("themeOptions", themeOptions, config.theme);

	const theme = responsiveFontSizes(
		createMuiTheme(
			{ ...baseThemeOptions },
			{ ...themeOptions },
			{
				direction: config.direction,
			}
		)
	);

	return theme;
};
