import "simplebar/dist/simplebar.min.css";
import "nprogress/nprogress.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SettingsProvider } from "./contexts/settings-context";
import { TitleProvider } from "./contexts/TitleContext";
import "./styles/plain.css";
import App from "./app";
import { createRoot } from 'react-dom/client';


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<SettingsProvider>
	<TitleProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</TitleProvider>
</SettingsProvider>);
